import { useEffect } from 'react'

import { getFbp } from '../utils/ads-info'
import { decryptUser } from '../utils/user'

function useAdsInfo() {
  const params = new URLSearchParams(window.location.search)

  useEffect(() => {
    const user = decryptUser()
    const search = Object.fromEntries(params.entries())
    const fbp = getFbp()

    if (fbp) {
      search.fbp = fbp
    }

    if (Object.keys(search).length && !user) {
      localStorage.setItem('ads_info', JSON.stringify(search))
    }
  }, [params])
}

export default useAdsInfo
